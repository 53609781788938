<template>
  <FormulateForm
    class="login"
    :class="{ login_sidebar: sidebar }"
    autocomplete="off"
    v-model="credentials"
    @submit="loginFN"
    :style="styles"
  >
    <h5 class="login-form-title" v-if="title">{{ labels.login }}</h5>
    <FormulateInput
      type="text"
      name="username"
      :placeholder="labels.username"
      validation="required"
      error-behavior="blur"
    />
    <FormulateInput
      type="password"
      name="password"
      :placeholder="labels.password"
      validation="required"
      validation-name="Password"
      error-behavior="blur"
    />
    <div class="d-flex login__actions">
      <FormulateInput
        class="submit btn-custom"
        type="submit"
        :label="labels.login"
      />
      <div class="text-center">
        <router-link
          class="form-pass-restore password-reset-link"
          to="/lost-password"
          :title="labels.forgot_password"
        >
          {{ labels.forgot_password }}
        </router-link>
      </div>
    </div>
    <div v-if="sidebar" class="choice-line-break">
      <router-link to="/register" :aria-label="labels.register">
        {{ labels.register }}
      </router-link>
    </div>
  </FormulateForm>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Login",
  props: {
    title: {
      type: String,
      default: "",
    },
    loginLabel: {
      type: String,
      default: "",
    },
    loginBtnWidth: {
      type: String,
      default: "auto",
    },
    sidebar: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      credentials: {
        username: "",
        password: "",
      },
      styles: {
        "--login-btn-width": this.loginBtnWidth,
      },
    };
  },
  computed: {
    ...mapGetters("helper", ["labels"]),
  },
  methods: {
    ...mapActions("notification", {
      addNotification: "addNotification",
    }),
    async loginFN() {
      // Wait until recaptcha has been loaded.
      // await this.$recaptchaLoaded();

      // Execute reCAPTCHA with action "login".
      // this.credentials.captcha = await this.$recaptcha('login');

      this.$store
        .dispatch("user/login", this.credentials)
        .then((res) => {
          if (res.success) {
            if (res.role === "account") {
              this.$router.push({ name: "account" });
            } else {
              this.$router.push({ name: "dashboard" });
            }
          } else {
            try {
              for (let i = 0; i < res.error.length; i++) {
                let nObj = {
                  variant: "danger",
                  msg: this.labels[res.error[i]] || res.error[i],
                };
                if (res.error[i] == "login_error_user_unverified")
                  nObj.buttons = [
                    {
                      title: this.labels.resend_verification,
                      fn: this.resend,
                    },
                  ];

                this.addNotification(nObj);
              }
            } catch (e) {
              this.addNotification({
                variant: "danger",
                msg: this.labels.unknown_server_issue,
              });
            }
          }
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.login {
  display: flex;
  flex-direction: column;

  ::v-deep .formulate-input {
    &.submit {
      margin-bottom: 0 !important;

      button {
        width: var(--login-btn-width);
        margin: 0 auto 15px;
      }
    }
  }

  &__actions {
    flex-direction: column;
  }

  &.login_sidebar {
    ::v-deep .formulate-input {
      .formulate-input-element {
        input {
          display: block;
          max-width: 100%;
          width: 100%;
          font-size: 0.875rem;
          border: 0;
          border-bottom: 1px solid $lightgray;
          border-radius: 0;
          background: transparent;
          color: $black;
          padding: 0;
          box-shadow: none;
          height: unset;

          &::-webkit-input-placeholder {
            /* Edge */
            color: $black;
          }
          &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $black;
          }
          &::placeholder {
            color: $black;
          }
        }
      }
    }
  }

  .form-pass-restore {
    font-size: 14px;
    margin-bottom: 15px;
    display: inline-block;
    color: #5b5b5b;

    &:hover {
      color: #0056b3;
      text-decoration: underline;
    }
  }

  .choice-line-break {
    position: relative;
    margin-bottom: 15px;
    text-align: center;
    font-size: 14px;

    &:before {
      position: absolute;
      content: "";
      width: 100%;
      height: 1px;
      background: #5b5b5b;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }

    span {
      position: relative;
      display: inline-block;
      z-index: 2;
      background: #fcfcfc;
      padding: 0 30px;
      color: #5b5b5b;
      text-transform: capitalize;
    }

    a {
      text-transform: uppercase;
      color: $navy;
      font-weight: 500;
      margin: 10px auto;
      position: relative;
      display: inline-block;
      z-index: 2;
      background: #fcfcfc;
      padding: 0 30px;
    }
  }
}
</style>
