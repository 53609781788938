<template>
    <Login :sidebar="false"/>
</template>

<script>
import Login from "@/components/Common/Login";

export default {
    components: {
        Login
    }
}
</script>

<style lang="scss" scoped>
    ::v-deep {
        .login__actions {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
    }
</style>